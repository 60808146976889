import ENG from "./langENG.svg"
import RU from "./langRU.svg"
import TUR from "./langTUR.svg"

let languages = [
	{img: ENG, name: "English", code: "en"},
	{img: RU, name: "Русский", code: "ru"},
	{img: TUR, name: "Turkey", code: "tu"},
];

export default languages;

