import { useState, useEffect, createContext, useContext } from 'react';
import { BrowserRouter,
  Route,
  Routes,
  Redirect } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';


import Table from './Table/Table';
import Controls from './Controls/Controls';
import Game from './Game/Game';

//import Layout from './pages/Layout';



const Page404 = (props, context) => {
  return (
    <span>404. Page does not exist</span>
  );
}


const App = () => {


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Game/>} />
        <Route element={<Page404/>} />
      </Routes>
    </BrowserRouter>
    );
}

export default App;
