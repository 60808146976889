let configProd = {
  backend: "https://api.ballsofdreams.r-evolution.org",
  fatbetBackend: "https://api.fatbet.org",
  frontend: "https://ballsofdreams.r-evolution.org",
  video1: "wss://video.r-evolution.org/api/ws?src=first_camera",
  video2: "wss://video.r-evolution.org/api/ws?src=second_camera",
  isSoundsDefault: true,
};
let configDev = {
  backend: "https://dev9.devcloudtech.online",
  fatbetBackend: "https://dev.devcloudtech.online",
  frontend: "https://dev8.devcloudtech.online",
  video1: "wss://video.r-evolution.org/api/ws?src=first_camera",
  video2: "wss://video.r-evolution.org/api/ws?src=second_camera",
  isSoundsDefault: true,
};

module.exports = process.env.REACT_APP_DEV === "true" ? configDev : configProd;
