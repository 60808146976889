import { makeAutoObservable, observable, action, computed, autorun, toJS } from 'mobx'

class BalanceStore {
    balance;
    demo;

    constructor() {
        this.balance = 0;
        this.realBalance = 0;
        this.demo = true;
    	makeAutoObservable(this);

    }

    updateBalance(amount) {
        this.balance = amount;
    }

    updateRealBalance(amount) {
        this.realBalance = amount;
    }

    updateDemo(value) {
        this.demo = value;
    }

}

const balanceStore = new BalanceStore();

export default balanceStore;
export { BalanceStore };