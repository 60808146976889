import { Link, BrowserRouter, useHistory as Router, Route, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { toJS } from "mobx"
import { useTranslation } from 'react-i18next';

import './Balance.scss';

/* stores */
import balanceStore from '../stores/balance-store';
import betStore from '../stores/bet-store';

const Balance = observer((props) => {
	const { t } = useTranslation();
  	return (
		<div className="Balance">
			<span>{t('balance')}: <b>{toJS(balanceStore.demo) ? 0 : toJS(balanceStore.balance)}</b></span>
		</div>
  	)
})

export default Balance;