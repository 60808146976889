import USD_Chip1 from "../chips/usd/chip10.svg"
import USD_Chip2 from "../chips/usd/chip50.svg"
import USD_Chip3 from "../chips/usd/chip100.svg"
import USD_Chip4 from "../chips/usd/chip500.svg"
import USD_Chip5 from "../chips/usd/chip1000.svg"

import KZT_Chip1 from "../chips/kzt/chip100.svg"
import KZT_Chip2 from "../chips/kzt/chip300.svg"
import KZT_Chip3 from "../chips/kzt/chip500.svg"
import KZT_Chip4 from "../chips/kzt/chip1000.svg"
import KZT_Chip5 from "../chips/kzt/chip3000.svg"

import ZAR_Chip1 from "../chips/zar/chip5.svg"
import ZAR_Chip2 from "../chips/zar/chip10.svg"
import ZAR_Chip3 from "../chips/zar/chip25.svg"
import ZAR_Chip4 from "../chips/zar/chip50.svg"
import ZAR_Chip5 from "../chips/zar/chip100.svg"

import NGN_Chip1 from "../chips/ngn/chip200.svg"
import NGN_Chip2 from "../chips/ngn/chip500.svg"
import NGN_Chip3 from "../chips/ngn/chip1000.svg"
import NGN_Chip4 from "../chips/ngn/chip1500.svg"
import NGN_Chip5 from "../chips/ngn/chip5000.svg"

let currency = {
	zar: {
		chips: [
			{ img: ZAR_Chip1, value: 5 },
			{ img: ZAR_Chip2, value: 10 },
			{ img: ZAR_Chip3, value: 25 },
			{ img: ZAR_Chip4, value: 50 },
			{ img: ZAR_Chip5, value: 100 },
		],
		limits: {
			inner: {
				min: 5,
				max: 125
			},
			outer: {
				min: 50,
				max: 400
			}
		}
	},
	kzt: {
		chips: [
			{ img: KZT_Chip1, value: 100 },
			{ img: KZT_Chip2, value: 300 },
			{ img: KZT_Chip3, value: 500 },
			{ img: KZT_Chip4, value: 1000 },
			{ img: KZT_Chip5, value: 3000 },
		],
		limits: {
			inner: {
				min: 100,
				max: 3000
			},
			outer: {
				min: 1000,
				max: 10000
			}
		}
	},
	ngn: {
		chips: [
			{ img: NGN_Chip1, value: 200 },
			{ img: NGN_Chip2, value: 500 },
			{ img: NGN_Chip3, value: 1000 },
			{ img: NGN_Chip4, value: 1500 },
			{ img: NGN_Chip5, value: 5000 },
		],
		limits: {
			inner: {
				min: 200,
				max: 5000
			},
			outer: {
				min: 1500,
				max: 15000
			}
		}
	},
	default: {
		chips: [
			{ img: NGN_Chip1, value: 200 },
			{ img: NGN_Chip2, value: 500 },
			{ img: NGN_Chip3, value: 1000 },
			{ img: NGN_Chip4, value: 1500 },
			{ img: NGN_Chip5, value: 5000 },
		],
		limits: {
			inner: {
				min: 200,
				max: 5000
			},
			outer: {
				min: 1500,
				max: 15000
			}
		}
	},
}

	// usd: {
	// 	chips: [
	// 		{ img: USD_Chip1, value: 10 },
	// 		{ img: USD_Chip2, value: 50 },
	// 		{ img: USD_Chip3, value: 100 },
	// 		{ img: USD_Chip4, value: 500 },
	// 		{ img: USD_Chip5, value: 1000 },
	// 	],
	// 	limits: {
	// 		inner: {
	// 			min: 10,
	// 			max: 1000
	// 		}
	// 		outer: {
	// 			min: 500,
	// 			max: 4000
	// 		}
	// 	},
	// }


export default currency;
