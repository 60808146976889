import { useState, useEffect, memo } from 'react';
import { observer } from 'mobx-react';
import { autorun, toJS } from "mobx"

/* stores */
import historyStore from '../stores/history-store';
import betStore from '../stores/bet-store';

import currencyConfig from '../utils/currency';


let Cell = observer(({bets, value, text, cssClass, makeBet}) => {

	let [chips, setChips] = useState(null);

	let [currentChips, setCurrentChips] = useState([]);

	// apply current currency
	useEffect(() => {
		if(betStore.currency && currencyConfig[betStore.currency]) {
			setChips(currencyConfig[betStore.currency].chips);
		} else {
			setChips(currencyConfig.default.chips);
		}
	}, [betStore.currency])

	useEffect(() => {
			if(!chips) return;

			if (bets.length === 0) return setCurrentChips([]);

			// find bets for this current cell in bets (it's only one position) 
			// and find associated bets with this cell (if bet was on cell cross)
			let betsAssociatedWithThisCell = bets.filter(o => ((JSON.stringify(o.bet) === JSON.stringify(value)) || ((value.length === 1) && (o.bet.length <= 6) && (o.bet[0] === value[0]))));


			let chipsToThisCell = [];

			betsAssociatedWithThisCell.forEach(betItem => {
				let offsetY, offsetX;

				if (betItem.bet.length === 4) {
                    if (betItem.bet[0] + 1 === betItem.bet[1]) {
                        offsetY = "top";
                        offsetX = "right";
                    }
                }

                if (betItem.bet.length === 3) {
                	// vertical street
                    offsetY = "bottom";
                    offsetX = "";
                }

                if (betItem.bet.length === 6) {
                	// vertical double street
                    offsetY = "bottom";
                    offsetX = "right";
                }

                if (betItem.bet.length === 3 && betItem.bet[2] === 37) {
                    offsetY = "top";
                    offsetX = "right";
                }


				if (betItem.bet.length === 2) {
                    if (betItem.bet[0] + 1 === betItem.bet[1]) offsetY = "top";
                    if (betItem.bet[0] - 1 === betItem.bet[1]) offsetY = "bottom";
                    if (betItem.bet[0] + 3 === betItem.bet[1]) offsetX = "right";
                    if (betItem.bet[0] - 3 === betItem.bet[1]) offsetX = "left";

                    if (betItem.bet[0] === 35 && betItem.bet[1] === 37) {
                        offsetX = "right";
                        offsetY = "";
                    }
                    if (betItem.bet[0] === 34 && betItem.bet[1] === 37) {
                        offsetX = "right";
                        offsetY = "";
                    }
                    if (betItem.bet[0] === 36 && betItem.bet[1] === 37) {
                        offsetX = "right";
                        offsetY = "";
                    }
                }
                let newTop = 25;
                if (offsetY == 'top') {
                	newTop = -25;
                }
                if (offsetY == 'bottom') {
                	newTop = -25;
                }
                //let newTop = offsetY == 'top' ? -25 : 25;
                newTop = (value[0] === 37) ? 42 : newTop;
				let sum = betItem.money.reduce((partialSum, a) => partialSum + a, 0);
				for (let i = chips.length - 1; i >= 0; i--) {
					if(sum > 0) {
						let n = Math.floor(sum / chips[i].value);
						sum -= chips[i].value * n;
						for (let j=0; j < n; j++) {
							let classes = "chip";
							if(offsetX) classes += " "+offsetX;
							if(offsetY) classes += " "+offsetY;

							if(offsetY == 'bottom') {
								newTop = value[0] !== 37 ? newTop+3 : newTop+1;
								chipsToThisCell.push({...chips[i], style: {bottom: newTop + "%"}, classes: classes})
							} else {
								newTop = value[0] !== 37 ? newTop-3 : newTop-1;
								chipsToThisCell.push({...chips[i], style: {top: newTop + "%"}, classes: classes})
							}
						}
					}
				}
				setCurrentChips(chipsToThisCell)
			})
	}, [bets, chips])

	return (
		<div className={cssClass + " " + ((value.length === 1 && historyStore.num === value[0]) ? "num" : "")} onClick={(e) => makeBet(e, value, text)}>
			<span>{text}</span>
			<div className="chips">
				{currentChips.map((item, key) =>
					<img className={item.classes} key={key} src={item.img} style={item.style} />
				)}
			</div>
		</div>
	)
})


export default Cell;