import { makeAutoObservable, observable, action, computed, autorun, toJS } from 'mobx'
import {Howl, Howler} from 'howler';

import config from '../config/config';

import timer from '../sounds/timer.wav';
import drawing from '../sounds/drawing.wav';
import win from '../sounds/win.wav';
import chip from '../sounds/chip.wav';
import change from '../sounds/change.wav';

import music from '../sounds/music.mp3';

class SoundStore {
    timerAudio;
    drawingAudio;
    winAudio;
    chipAudio;
    changeAudio;
    musicAudio;

    mute;

    constructor() {
        this.mute = config.isSoundsDefault ? false : true;
        this.musicAudio = new Howl({
          src: [music]
        });
        this.chipAudio = new Howl({
          src: [chip]
        });
        this.changeAudio = new Howl({
          src: [change]
        });
        this.winAudio = new Howl({
          src: [win]
        });
        this.drawingAudio = new Howl({
          src: [drawing]
        });
        this.timerAudio = new Howl({
          src: [timer]
        });
        makeAutoObservable(this);

    }

    clickSoundBtn() {
        this.timerAudio.load()
        this.drawingAudio.load()
        this.winAudio.load()
        this.chipAudio.load()
        this.changeAudio.load()
        this.musicAudio.load()
    }

    playTimer() {
        this.timerAudio.pause();
        this.timerAudio.seek(0);
        if (!this.mute) {
            this.timerAudio.volume(1);
            this.timerAudio.play();
        } else {
            this.timerAudio.volume(0);
            this.timerAudio.play();
        }
    }

    stopTimer() {
        this.timerAudio.pause();
    }


    playDrawing() {
        this.drawingAudio.pause();
        this.drawingAudio.seek(0);
        if (!this.mute) {
            this.drawingAudio.volume(1);
            this.drawingAudio.play();
        } else {
            this.drawingAudio.volume(0);
        }
    }

    playWin() {
        this.winAudio.pause();
        this.winAudio.seek(0);
        if (!this.mute) {
            this.winAudio.volume(1);
            this.winAudio.play();
        } else {
            this.winAudio.volume(0);
        }
    }

    playChip() {
        this.chipAudio.pause();
        this.chipAudio.seek(0);
        if (!this.mute) {
            this.chipAudio.volume(1);
            this.chipAudio.play();
        } else {
            this.chipAudio.volume(0);
        }
        
    }

    playChange() {
        this.changeAudio.pause();
        this.changeAudio.seek(0);
        if (!this.mute) {
            this.changeAudio.volume(1);
            this.changeAudio.play();
        } else {
            this.changeAudio.volume(0);
        }
    }



    playMusic() {
        this.musicAudio.seek(0);

        if (!this.mute) {
            this.musicAudio.volume(0);
            this.musicAudio.play();
            this.musicAudio.fade(0, 1, 2000);
        } else {
            this.musicAudio.volume(0);
            this.musicAudio.play();
        }
        
    }

    stopMusic() {
        if (!this.mute) {
            this.musicAudio.fade(1, 0, 1000);
            setTimeout(() => {
                this.musicAudio.pause();
            }, 1000)
        }
    }

    setMute() {
        this.mute = true;
        this.musicAudio.volume(0);
        this.timerAudio.volume(0);
    }

    setUnmute() {
        this.mute = false;
        this.musicAudio.fade(0, 1, 2000);
        this.timerAudio.volume(1);
    }

}

const soundStore = new SoundStore();

export default soundStore;
export { SoundStore };