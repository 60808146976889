import { Link, BrowserRouter, useHistory as Router, Route, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { toJS } from "mobx"
import { useTranslation } from 'react-i18next';

import './Win.scss';

/* stores */
import historyStore from '../stores/history-store';
import tableStore from '../stores/table-store';

const Win = observer((props) => {
	const { t } = useTranslation();
	let [red] = useState([1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36])
  	return (
  			<div className={"Win " + (!tableStore.open ? "" : "table_open") + " " + (historyStore.num ? "d-flex" : "d-none") + " " + (historyStore.num === 37 ? "green" : red.findIndex(item => item === historyStore.num) !== -1 ? "red" : "black")}>
				<span>{historyStore.num}</span>
				<div className="Win__description">
					<span>{t('win')} {props.win}</span>
				</div>
			</div>

  	)
})

export default Win;