import { useState, useEffect } from "react";
import { io } from "socket.io-client";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";

import "./Jackpot.scss";

/* stores */
import config from "../config/config";

const Jackpot = (props) => {
  const [value, setValue] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    const socket = io(config.fatbetBackend + "/weeklyJackpot", {
      withCredentials: true,
      reconnectionDelayMax: 10000,
      forceNew: true,
    });

    socket.on("weeklyJackpotData", (data) => {
      setValue(data.sum);
    });
    socket.on("weeklyJackpotUpdate", (sum) => {
      setValue(sum);
    });

    return () => {
      socket.disconnect();
      setValue(null);
    };
  }, []);
  return (
    value && (
      <div className="Jackpot">
        <span>
          {t("jackpot_text")}:{" "}
          <CountUp
            end={value}
            enableScrollSpy
            scrollSpyDelay={0}
            scrollSpyOnce
            preserveValue={true}
            separator=" "
            startOnMount={false}
            duration={1}
            suffix=" KZT"
          >
            {({ countUpRef }) => <b ref={countUpRef} />}
          </CountUp>
        </span>
      </div>
    )
  );
};

export default Jackpot;
