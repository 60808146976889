import { useState, useEffect, useRef } from "react";
import Modal from 'react-modal';

import Cross from './cross.svg';
import './DescriptionModal.scss';

let DescriptionModal = (props) => {
  /*
  props.modalIsOpen
  props.modalIsOpenCallback
  props.modalHeader
  props.modalText
  */


  const [modalIsOpen, setModalIsOpen] = useState(false);

  // CLOSE MODAL
  let closeModal = () => {
    setModalIsOpen(false);
    props.modalIsOpenCallback(false);
  }
  // END OF CLOSE MODAL


  // SYNC MODAL_IS_OPEN STATE
  useEffect(() => {
    setModalIsOpen(props.modalIsOpen);
  }, [props.modalIsOpen])
  // END OF SYNC MODAL_IS_OPEN STATE


  // HANDLE CLICK OUTSIDE
  const wrapperRef = useRef(null);
  useEffect(() => {
    let handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) closeModal();
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // END OF HANDLE CLICK OUTSIDE

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      overlayClassName="DescriptionModal__Overlay"
      className="DescriptionModal__Content container h-100"
    >
      <div className="row mx-0 justify-content-center h-100 align-content-sm-center align-items-sm-center align-content-end align-items-end">
      <div className="DescriptionModal__Holder col-12 col-md-10 col-lg-8 col-xxl-5">
        <div ref={wrapperRef} className="DescriptionModal">
          <div className="holder_close-modal-button">
            <button onClick={closeModal} className="close-modal-button"><img src={Cross} alt=""/></button>
          </div>
          
            <div className="DescriptionModal__Header d-flex mb-2 justify-content-center align-items-center text-center">
              <h2>{props.modalHeader}</h2>
            </div>
            <div className="modal__content_holder">
            <div className="DescriptionModal__Body pr-2" dangerouslySetInnerHTML={{ __html: props.modalText }}></div>
        </div>
        </div>
      </div>
      </div>
    </Modal>
  );
}

export default DescriptionModal;
