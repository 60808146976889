import {
  Link,
  BrowserRouter,
  useHistory as Router,
  Route,
  useHistory,
} from "react-router-dom";
import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import ReactPlayer from "./ReactWebRTCPlayer/Player";

import { io } from "socket.io-client";

import "./Game.scss";

import Table from "../Table/Table";
import Controls from "../Controls/Controls";
import Balance from "../Balance/Balance";
import Timer from "../Timer/Timer";
import Settings from "../Settings/Settings";
import Win from "../Win/Win";
import Jackpot from "../Jackpot/Jackpot";

import config from "../config/config";

import timer from "../sounds/timer.wav";

/* stores */
import betStore from "../stores/bet-store";
import balanceStore from "../stores/balance-store";
import tableStore from "../stores/table-store";
import historyStore from "../stores/history-store";
import soundStore from "../stores/sound-store";

const Game = observer((props) => {
  const { t } = useTranslation();

  const [isFirstPlayer, setIsFirstPlayer] = useState(true);
  const [isSecondPlayer, setIsSecondPlayer] = useState(false);

  let [chip, setChip] = useState(null);

  let [lastNums, setlastNums] = useState([]);
  let [num, setNum] = useState(null);

  let [timeRound, setTimeRound] = useState(60);
  let [leftTime, setLeftTime] = useState(36);
  let [win, setWin] = useState(0);
  let [balance, setBalance] = useState(0);
  let [errors, setErrors] = useState(false);

  const [isConnected, setIsConnected] = useState(false);

  let chooseChip = (e) => {
    setChip(e);
  };

  let [roundName, setRoundName] = useState(null);

  let [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    if (roundName && isConnected) {
      setTimeout(() => {
        setShowLoading(false);
      }, 5000);
    }
  }, [roundName, isConnected]);

  useEffect(() => {
    const socket = io(config.backend, {
      withCredentials: true,
    });

    let betsSended = false;
    let timerPlay = false;
    let darwingPlay = false;
    socket.on("connect", () => {
      if (isConnected) return;
      let socketID = socket.id;
      let userKey;

      const params = new URLSearchParams(window.location.hash.substring(1));
      const obj = {};
      for (const key of params.keys()) {
        if (params.getAll(key).length > 1) {
          obj[key] = params.getAll(key);
        } else {
          obj[key] = params.get(key);
        }
      }

      if (obj.demo === "false") {
        if (document.querySelector("html").getAttribute("userKeySocket")) {
          userKey = document
            .querySelector("html")
            .getAttribute("userKeySocket");
        } else {
          userKey =
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);
          document.documentElement.setAttribute("userKeySocket", userKey);
        }
        console.log("obj", obj);
        console.log("obj.currency", obj.currency);
        betStore.setCurrency(obj.currency);
        if (obj.currency === "kzt") {
          betStore.setIsKazPlayer(true);
        }
      } else {
        if (document.querySelector("html").getAttribute("userKeySocketDemo")) {
          userKey = document
            .querySelector("html")
            .getAttribute("userKeySocketDemo");
        } else {
          userKey =
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);
          document.documentElement.setAttribute("userKeySocketDemo", userKey);
        }
      }

      if (obj.language) {
        if (obj.language === "kk") {
          betStore.setIsKazPlayer(true);
        }
        i18next.changeLanguage(obj.language === "kk" ? "ru" : obj.language);
        document.documentElement.setAttribute(
          "lang",
          obj.language === "kk" ? "ru" : obj.language
        );
        betStore.setLanguage(obj.language === "kk" ? "ru" : obj.language);
      }

      if (obj.demo === "false") balanceStore.updateDemo(false);

      socket.emit("setSocketId", {
        socketID: socketID,
        userKey: userKey,
        ...obj,
      });

      setIsConnected(true);

      console.log(
        "CONNECT --- document.querySelector('html').getAttribute('roundId')",
        document.querySelector("html").getAttribute("roundId")
      );

      if (!document.querySelector("html").getAttribute("sound"))
        soundStore.setUnmute();
      else if (document.querySelector("html").getAttribute("sound") === "on")
        soundStore.setUnmute();
      else soundStore.setMute();
    });

    let visibilitychangeEventListner = () => {
      if (document.hidden) socket.disconnect();
      else socket.connect();
    };

    document.addEventListener("visibilitychange", visibilitychangeEventListner);

    let clearGame = () => {
      if (tableStore.open) tableStore.unblockTable();
      betStore.clear();
      setWin(0);
      historyStore.updateLastNums();
    };

    socket.on("disconnect", () => {
      console.log(
        "DISCONNECT --- document.querySelector('html').getAttribute('roundId')",
        document.querySelector("html").getAttribute("roundId")
      );
      console.log("disconnect");
      setIsConnected(false);
      soundStore.setMute();
    });

    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });

    socket.on("error", (data) => {
      setErrors(data);
      socket.disconnect();
    });

    socket.on("fell-out-last", (data) => {
      historyStore.setLastNums(data.nums.slice(0, 7));
    });

    socket.on("newRound", (data) => {
      betsSended = false;
      setTimeRound(data.stepLimits);
      setLeftTime(data.timeLeft);
      clearGame();
      setRoundName("newRound");
      setIsFirstPlayer(false);
      setIsSecondPlayer(true);
      document.documentElement.setAttribute("roundId", data.roundId);
    });

    socket.on("acceptingBidsStarts", (data) => {
      setTimeRound(data.stepLimits);
      setLeftTime(data.timeLeft);
      setRoundName("acceptingBidsStarts");
      setIsFirstPlayer(false);
      setIsSecondPlayer(true);
      if (document.querySelector("html").getAttribute("roundId")) {
        if (
          data.roundId &&
          data.roundId !==
            document.querySelector("html").getAttribute("roundId")
        ) {
          clearGame();
          document.documentElement.setAttribute("roundId", data.roundId);
        }
      } else document.documentElement.setAttribute("roundId", data.roundId);
    });

    socket.on("acceptingBidsInProcess", (data) => {
      setTimeRound(data.stepLimits);
      setLeftTime(data.timeLeft);
      setRoundName("acceptingBidsInProcess");
      setIsFirstPlayer(false);
      setIsSecondPlayer(true);

      if (document.querySelector("html").getAttribute("roundId")) {
        if (
          data.roundId &&
          data.roundId !==
            document.querySelector("html").getAttribute("roundId")
        ) {
          clearGame();
          document.documentElement.setAttribute("roundId", data.roundId);
        }
      } else document.documentElement.setAttribute("roundId", data.roundId);
    });

    socket.on("acceptingBidsComingToEnd", (data) => {
      setTimeRound(data.stepLimits);
      setLeftTime(data.timeLeft);
      setRoundName("acceptingBidsComingToEnd");
      if (!timerPlay) soundStore.playTimer();
      timerPlay = true;
      setIsFirstPlayer(false);
      setIsSecondPlayer(true);

      if (document.querySelector("html").getAttribute("roundId")) {
        if (
          data.roundId &&
          data.roundId !==
            document.querySelector("html").getAttribute("roundId")
        ) {
          clearGame();
          document.documentElement.setAttribute("roundId", data.roundId);
        }
      } else document.documentElement.setAttribute("roundId", data.roundId);
    });

    socket.on("drawingStarts", (data) => {
      setTimeRound(data.stepLimits);
      setLeftTime(data.timeLeft);
      if (!betsSended) socket.emit("send-bets", { bets: toJS(betStore.bets) });
      else return;
      betsSended = true;
      tableStore.blockTable();
      setRoundName("drawingStarts");
      timerPlay = false;
      soundStore.stopTimer();
      if (!darwingPlay) soundStore.playDrawing();
      darwingPlay = true;
      soundStore.playMusic();
      setIsFirstPlayer(true);
      setIsSecondPlayer(false);

      if (document.querySelector("html").getAttribute("roundId")) {
        if (
          data.roundId &&
          data.roundId !==
            document.querySelector("html").getAttribute("roundId")
        ) {
          clearGame();
          document.documentElement.setAttribute("roundId", data.roundId);
        }
      } else document.documentElement.setAttribute("roundId", data.roundId);
    });

    socket.on("drawingInProcess", (data) => {
      setTimeRound(data.stepLimits);
      setLeftTime(data.timeLeft);
      if (!betsSended) betStore.clear();

      tableStore.blockTable();
      setRoundName("drawingInProcess");
      darwingPlay = false;
      setIsFirstPlayer(true);
      setIsSecondPlayer(false);

      if (document.querySelector("html").getAttribute("roundId")) {
        if (
          data.roundId &&
          data.roundId !==
            document.querySelector("html").getAttribute("roundId")
        ) {
          clearGame();
          document.documentElement.setAttribute("roundId", data.roundId);
        }
      } else document.documentElement.setAttribute("roundId", data.roundId);
    });

    socket.on("raffle-is-completed", (data) => {
      setTimeRound(data.stepLimits);
      setLeftTime(data.timeLeft);
      tableStore.blockTable();
      if (data.num) historyStore.setNum(data.num);
      setRoundName("raffle-is-completed");
      soundStore.stopMusic();
      setIsFirstPlayer(true);
      setIsSecondPlayer(false);
      if (!betsSended) betStore.clear();
      document.documentElement.setAttribute("roundId", data.roundId);
    });

    socket.on("your-win", (data) => {
      setWin(data.win);
      if (data.win > 0) soundStore.playWin();
      tableStore.blockTable();
    });

    socket.on("balance", (data) => {
      console.log("data", data);
      balanceStore.updateBalance(data.balance);
      balanceStore.updateRealBalance(data.balance);
    });

    //let params = window.location.hash;
    //let iterator = params.entries()
    //console.log("params", params);

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.disconnect();

      window.removeEventListener(
        "visibilitychange",
        visibilitychangeEventListner
      );
    };
  }, []);

  let [soundLoad, setSoundLoad] = useState(false);

  useEffect(() => {
    window.scroll(0, 1);

    let touchStartEventListner = () => {
      if (config.isSoundsDefault && !soundLoad) {
        soundStore.clickSoundBtn();
        setSoundLoad(true);
      }
    };

    document.body.addEventListener("touchstart", touchStartEventListner, false);

    return () => {
      document.body.removeEventListener(
        "touchstart",
        touchStartEventListner,
        false
      );
    };
  }, []);

  /* const qs = `?size=M&size=XL&price=29&sort=desc`

const params = new URLSearchParams(qs)

const obj = {}
for (const key of params.keys()) {
  if (params.getAll(key).length > 1) {
    obj[key] = params.getAll(key)
  } else {
    obj[key] = params.get(key)
  }
}

console.log(obj)
// { size: [ 'M', 'XL' ], price: '29', sort: 'desc' } */
  return (
    <div className="Game d-flex flex-wrap">
      <div className="video">
        {/*<ReactPlayer 
			        display={(roundName === "drawingStarts" || roundName === "drawingInProcess" || roundName === "raffle-is-completed") ? true : false}
			        url="wss://video.amont.studio/api/ws?src=first_camera" 
			        name="first_camera" 
			        controls={false} 
			    />

			    <ReactPlayer 
			        display={(roundName !== "drawingStarts" && roundName !== "drawingInProcess" && roundName !== "raffle-is-completed") ? true : false}
			        url="wss://video.amont.studio/api/ws?src=second_camera" 
			        name="second_camera" 
			        controls={false} 
			    />*/}

        <ReactPlayer
          display={true}
          url={config.video2}
          name="second_camera"
          controls={false}
        />
      </div>
      <div className="d-flex flex-column d-sm-none justify-content-center align-items-center w-100 h-100 position-absolute mobile">
        <div class="phone"></div>
        <span className="mt-4">{t("flip_device_text")}</span>
      </div>
      <div
        className={
          "justify-content-center align-items-center w-100 h-100 position-absolute connect " +
          (isConnected && roundName && !showLoading ? "d-none" : "d-flex")
        }
      >
        <span className="d-inline-block mb-3">Loading game...</span>
        <div className="loader"></div>
      </div>

      <div
        className={
          "justify-content-center align-items-center text-center w-100 h-100 position-absolute error " +
          (!errors ? "d-none" : "d-flex")
        }
      >
        {errors.text !== "error" ? (
          <>
            <h5 className="d-inline-block mb-3">{errors.title}</h5>
            <p className="d-inline-block mb-3">{errors.text}</p>
          </>
        ) : (
          <>
            <h5 className="d-inline-block mb-3">
              Lost connection with lotomachine
            </h5>
            <p className="d-inline-block mb-3">
              The lotomachine stopped responding to our requests. We're trying
              to reconnect. If you placed bets in the last round, they are
              canceled and have already returned to the balance. Please try to
              log in to the game later or refresh the page in a couple of
              minutes
            </p>
          </>
        )}
        <button type="button" onClick={() => window.location.reload()}>
          Refresh page
        </button>
      </div>

      <div className="Game__info w-100">
        <div className="container-fluid">
          <div className="row justify-content-center m-0">
            <div className="col-12 col-lg-10 col-xl-8 d-flex justify-content-between align-items-center">
              <Balance balance={balance} roundName={roundName} />
              <Timer
                timeRound={timeRound}
                leftTime={leftTime}
                roundName={roundName}
              />
              <Settings />
            </div>
          </div>
          {betStore.isKazPlayer ? (
            <div className="row justify-content-center m-0 mt-1">
              <div className="col-12 col-lg-10 col-xl-8 d-flex justify-content-end align-items-center">
                <Jackpot />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Win win={win} />
      <div
        className={`Game__interactive w-100 position-relative ${
          !toJS(tableStore.open) ? "" : "active"
        }`}
      >
        <div className="container-fluid">
          <div className="row justify-content-center m-0">
            <div className="col-12 col-lg-10 col-xl-8 position-relative holder">
              <Table
                chip={chip}
                roundName={roundName}
                isConnected={isConnected}
              />
            </div>
          </div>
          <div className="row justify-content-center m-0">
            <div className="col-12 col-lg-10 col-xl-8">
              <Controls
                chooseChip={chooseChip}
                bet={betStore.getBetsSum()}
                win={win}
                lastNums={lastNums}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Game;
