import { Link, BrowserRouter, useHistory as Router, Route, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './Timer.scss';

const Timer = (props) => {

	let [radius] = useState(17);
	let [circumference] = useState(2 * Math.PI * radius);
	let [offset, setOffset] = useState(circumference - 0*circumference);
	const { t } = useTranslation();

	let [rounds] = useState([
		{code: "newRound", description: t('acceptingBidsStarts'), table: "noblock"},
		{code: "acceptingBidsStarts", description: t('acceptingBidsStarts'), table: "noblock"},
		{code: "acceptingBidsInProcess", description: t('acceptingBidsInProcess'), table: "noblock"},
		{code: "acceptingBidsComingToEnd", description: t('acceptingBidsComingToEnd'), table: "noblock"},
		{code: "drawingStarts", description: t('drawingStarts'), table: "block"},
		{code: "drawingInProcess", description: t('drawingInProcess'), table: "block"},
		{code: "raffle-is-completed", description: t('Result'), table: "block"},
	])

	useEffect(() => {
		setOffset(circumference - props.leftTime/props.timeRound*circumference);
	}, [props]);


  	return (
		<div className="Timer d-flex align-items-center">
			<div className="time mr-1 position-relative">
				<span>{ props.leftTime}</span>
				<svg className="progress-ring" width="36" height="36" >
				  	<circle className="progress-ring__circle" cx="18" cy="18" r={radius} style={{strokeDasharray: circumference + " " + circumference, strokeDashoffset: offset}}></circle>
				</svg>
			</div>
			<div className="status"><span>
			{(props.roundName === "newRound" || props.roundName === "acceptingBidsStarts") ? t('acceptingBidsStarts') 
			: (props.roundName === "acceptingBidsInProcess") ? t('acceptingBidsInProcess') 
			: (props.roundName === "acceptingBidsComingToEnd") ? t('acceptingBidsComingToEnd')
			: (props.roundName === "drawingStarts") ? t('drawingStarts')
			: (props.roundName === "drawingInProcess") ? t('drawingInProcess')
			: (props.roundName === "raffle-is-completed") ? t('Result') : ""}
			</span></div>
			
		</div>
  	)
}

export default Timer;