    /**
     * Finding parent element with class
     * @method findParent
     * @memberof Bingo37
     * @param {NodeElement} el - NodeElement
     * @param {String} cls - Class of parent
     * @returns {NodeElement} Parent NodeElement
     */
    let findParent = (el, cls) => {
        while ((el = el.parentElement) && !el.classList.contains(cls));
        return el;
    }

    /**
     * This function returns the value of the cell at the top (if any)
     * @method getCellValueOnThe_top
     * @memberof Bingo37
     * @param {Number} cellValue - The value of cell
     * @returns {Number} CellValue
     */
    let getCellValueOnThe_top = (cellValue) => {
        console.log("cellValue", cellValue);
        return cellValue % 3 !== 0 && cellValue !== 37 ? cellValue + 1 : null;
    }

    /**
     * This function returns the value of the cell at the bottom (if any)
     * @method getCellValueOnThe_top
     * @memberof Bingo37
     * @param {Number} cellValue - The value of cell
     * @returns {Number} CellValue
     */
    let getCellValueOnThe_bottom = (cellValue) => {
        console.log("cellValue", cellValue);
        return cellValue % 3 !== 1 ? cellValue - 1 : null;
    }

    /**
     * This function returns the value of the cell at the left (if any)
     * @method getCellValueOnThe_left
     * @memberof Bingo37
     * @param {Number} cellValue - The value of cell
     * @returns {Number} CellValue
     */
    let getCellValueOnThe_left = (cellValue) => {
        if (cellValue === 37) return cellValue - 2;
        return cellValue !== 1 && cellValue !== 2 && cellValue !== 3 ? cellValue - 3 : null;
    }

    /**
     * This function returns the value of the cell at the right (if any)
     * @method getCellValueOnThe_right
     * @memberof Bingo37
     * @param {Number} cellValue - The value of cell
     * @returns {Number} CellValue
     */
    let getCellValueOnThe_right = (cellValue) => {
        console.log("cellValue", cellValue);
        return cellValue === 34 || cellValue === 35 || cellValue === 36 ? 37 : cellValue !== 37 ? cellValue + 3 : null;
    }

    /**
     * This function returns the value of the fourth cell (when you now clicked cell and offsets)
     * @method findTheFourthCell
     * @memberof Bingo37
     * @param {Number} cellValue - The value of cell
     * @param {String} offsetX - Left or right indent
     * @param {String} offsetY - Top or bottom indent
     * @returns {Number} CellValue
     */
    let findTheFourthCell = (cellValue, offsetX, offsetY) => {
        if (cellValue === 34 || cellValue === 35 || cellValue === 36) return false;
        return offsetX == "left" && offsetY == "top" ? cellValue - 2 : offsetX == "left" && offsetY == "bottom" ? cellValue - 4 : offsetX == "right" &&
            offsetY == "top" ? cellValue + 4 : offsetX == "right" && offsetY == "bottom" ? cellValue + 2 : null;
    }



    export {
        findParent,
        getCellValueOnThe_top,
        getCellValueOnThe_bottom,
        getCellValueOnThe_left,
        getCellValueOnThe_right,
        findTheFourthCell
    }