import { Link, BrowserRouter, useHistory as Router, Route, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { autorun, toJS } from "mobx"
import Wrapper from './Wrapper';
import Cell from './Cell';
import { useTranslation } from 'react-i18next';

import './Table.scss';

import tableScheme from './scheme';
//import * as helpers from './helpers';

import down from './down.svg';

import * as helpers from './helpers';

/* stores */
import betStore from '../stores/bet-store';
import tableStore from '../stores/table-store';
import historyStore from '../stores/history-store';

const Table = observer((props) => {
    // open-close table
    let [closeTable, setCloseTable] = useState(false);
    let [notify, setNotify] = useState(null);

    let [round, setRound] = useState(null);

    const { t } = useTranslation();

    let openTable = () => {
        if (!closeTable) return;
    	if (props.roundName !== "drawingStarts" && props.roundName !== "drawingInProcess" && props.roundName !== "raffle-is-completed" ) {
    		if (closeTable) tableStore.unblockTable();
    	}
        if (closeTable) {
            setCloseTable(false);
            tableStore.openTable();
        }
    }

    let hiddenTable = () => {
        setCloseTable(true);
        tableStore.closeTable();
        tableStore.blockTable();
    }

    let clearGame = () => {
            if (tableStore.open) tableStore.unblockTable();
            betStore.clear();
            //setWin(0);
            historyStore.updateLastNums();
        }

    useEffect(() => {  
        //if (props.roundName === "drawingStarts" || props.roundName === "drawingInProcess" || props.roundName === "raffle-is-completed") clearGame();
        if (props.roundName === "newRound" && round === "raffle-is-completed") {
            openTable();
            setRound(props.roundName);
        } else if (props.roundName === "drawingStarts" && round === "acceptingBidsComingToEnd") {
            hiddenTable();
            setRound(props.roundName);
        } else {
            setRound(props.roundName);
        }

        if (!round) {
            if (props.roundName === "drawingStarts" || props.roundName === "drawingInProcess" || props.roundName === "raffle-is-completed") {
                hiddenTable();
            } else {
                openTable();
            }
        }
    }, [props.roundName]);

    useEffect(() => {
        if (!props.isConnected) setRound(null);
    }, [props.isConnected])

    // table schema
    let [table] = useState(tableScheme);

    let makeBet = (e, value, text) => {
    	if (tableStore.block) {
    		if (!closeTable) setNotify({type: "not_action"});
    		setTimeout(() => {
    			setNotify(null);
    		}, 2000)
    		return false;
    	}
        let cell = e.target.classList.contains("clickArea") ? e.target : helpers.findParent(e.target, 'clickArea');
        if (!cell) return false;


        let target = cell.getBoundingClientRect();
        let width = target.width;
        let height = target.height;
        let x = e.clientX - target.left;
        let y = e.clientY - target.top;

        let currentBet = [],
            offsetX, offsetY;

        let limitType;

        let cellValue = value.length === 1 ? value[0] : value;

        if (typeof(cellValue) == 'object') {
            limitType = "outer";
            currentBet = cellValue;
            if(text === "1st12" || text === "2nd12" || text === "3rd12") {
                // vertical street and vertical double street
                if (y <= height / 4) {
                    // clicked to top
                    if (x <= width / 16 * 1) {
                        if(text === "1st12") currentBet = cellValue.slice(0,3);
                        else currentBet = cellValue.slice(0,3).concat([cellValue[0]-1, cellValue[0]-2, cellValue[0]-3]);
                    }
                    if ((x > (width / 16 * 1)) && (x <= width / 16 * 3)) {
                        currentBet = cellValue.slice(0,3);
                    }
                    if ((x > (width / 16 * 3)) && (x <= width / 16 * 5)) {
                        currentBet = cellValue.slice(0,6);
                    }
                    if ((x > (width / 16 * 5)) && (x <= width / 16 * 7)) {
                        currentBet = cellValue.slice(3,6);
                    }
                    if ((x > (width / 16 * 7)) && (x <= width / 16 * 9)) {
                        currentBet = cellValue.slice(3,9);
                    }
                    if ((x > (width / 16 * 9)) && (x <= width / 16 * 11)) {
                        currentBet = cellValue.slice(6,9);
                    }
                    if ((x > (width / 16 * 11)) && (x <= width / 16 * 13)) {
                        currentBet = cellValue.slice(6,12);
                    }
                    if ((x > (width / 16 * 13)) && (x <= width / 16 * 15)) {
                        currentBet = cellValue.slice(9,12);
                    }
                    if (x > width / 16 * 15) {
                        if(text === "3rd12") currentBet = cellValue.slice(9,12);
                        else currentBet = cellValue.slice(9,12).concat([cellValue[11]+3, cellValue[11]+4, cellValue[11]+5]);
                    }
                }
            }
        } else {
            limitType = "inner";
            currentBet.push(cellValue);

            if (x <= width / 4) {
                // clicked to left
                let additionalValue = helpers.getCellValueOnThe_left(cellValue);
                if (additionalValue) {
                    offsetX = "left";
                    currentBet.push(additionalValue);
                }
            }
            if (x >= (width / 4) * 3) {
                // clicked to right
                let additionalValue = helpers.getCellValueOnThe_right(cellValue);
                if (additionalValue) {
                    offsetX = "right";
                    currentBet.push(additionalValue);
                }
            }
            if (y <= height / 4) {
                // clicked to top
                let additionalValue = helpers.getCellValueOnThe_top(cellValue);
                offsetY = "top";
                if (additionalValue) {
                    currentBet.push(additionalValue);
                }
            }
            if (y >= (height / 4) * 3) {
                // clicked to bottom
                let additionalValue = helpers.getCellValueOnThe_bottom(cellValue);
                offsetY = "bottom";
                if (additionalValue) {
                    currentBet.push(additionalValue);
                }
            }

            if(currentBet.length === 1 && offsetY === "bottom" && !offsetX) {
                // vertical street
                currentBet.push(cellValue+1);
                currentBet.push(cellValue+2);
            }

            if(currentBet.length === 2 && offsetY === "bottom" && offsetX) {
                // vertical double street
                if(offsetX === "right") {
                    currentBet.push(cellValue+1, cellValue+2, cellValue+4, cellValue+5);
                }
                if(offsetX === "left") {
                    currentBet.push(cellValue+1, cellValue+2, cellValue-1, cellValue-2);
                }
            }

            if (currentBet.length === 3) {
                let additionalValue = helpers.findTheFourthCell(
                    cellValue,
                    offsetX,
                    offsetY
                );
                if (additionalValue) currentBet.push(additionalValue);
            }
        }
        currentBet.sort((a, b) => a - b);

        betStore.addBets(currentBet, props.chip, limitType);

    }


    return (
        <div className={`Table ${closeTable ? "inactive animation" : "animationtwo"}`} onClick={() => {openTable()}}>
			<button className="close-btn position-absolute" onClick={(e) => hiddenTable()}><img src={down} alt="" /></button>
			{
			toJS(betStore.notify) ? <div className="notify"><span>{t(toJS(betStore.notify.type)) + (toJS(betStore.notify.info) ? (" " + toJS(betStore.notify.info)) : "")}</span></div>
			: notify && notify.type ? <div className="notify"><span>{t(notify.type)}</span></div> : ""
			}
			<div className="Table__container">
				{table.map((group, key) => 
					group.isWrapper ? 
						<Wrapper key={key} className={group.cssClass}>
							{group.content.map((item, key2) => 
								<Cell {...item} key={key2} bets={toJS(betStore.bets)} makeBet={makeBet} />
							)}
						</Wrapper>
					:
					group.content.map((item, key2) => 
						<Cell {...item} key={key2} bets={toJS(betStore.bets)} makeBet={makeBet} />
					)
				)}
			</div>
		</div>
    )
})

export default Table;