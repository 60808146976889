import { makeAutoObservable, observable, action, computed, autorun, toJS } from 'mobx'

class HistoryStore {
    lastNums;
    num;

    constructor() {
        this.lastNums = [];
        this.num = null;
    	makeAutoObservable(this);

    }

    setLastNums(arr) {
        this.lastNums = arr;
    }

    updateLastNums() {
        if (this.num) {
            this.lastNums.unshift(this.num);
            this.num = null;
        }
    }

    setNum(value) {
        this.num = value;
    }

}

const historyStore = new HistoryStore();

export default historyStore;
export { HistoryStore };