import { memo } from 'react';

let Wrapper = memo(({children, className}) => {
		return (
			<div className={className}>
				{children}
			</div>
		)
	})

export default Wrapper;