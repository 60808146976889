import {
  Link,
  BrowserRouter,
  useHistory as Router,
  Route,
  useHistory,
} from "react-router-dom";
import { useState, useEffect } from "react";
import DescriptionModal from "../utils/DescriptionModal/DescriptionModal";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import {
  isMobileSafari,
  isMobile,
  isSafari,
  isTablet,
} from "react-device-detect";

import i18next from "i18next";
import { useTranslation } from "react-i18next";

import "./Settings.scss";

import info from "./info.svg";
import settings from "./settings.svg";
import fullscreen from "./fullscreen.svg";
import closeFullscreenIcon from "./closeFullscreen.svg";
import check from "./check.svg";
import close from "./close.svg";
import on from "./on.svg";
import off from "./off.svg";

import languages from "../utils/languages";

/* stores */
import soundStore from "../stores/sound-store";
import betStore from "../stores/bet-store";

const Settings = observer((props) => {
  const { t } = useTranslation();

  let [opened, setOpened] = useState(false);

  let openFullscreen = () => {
    if (!opened) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        /* Safari */
        document.documentElement.webkitRequestFullscreen();
      }
      setOpened(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      }
      setOpened(false);
    }
  };

  let closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
    setOpened(false);
  };

  //let [currentLanguage, setCurrentLanguage] = useState("en")
  let [openSelectLanguages, setOpenSelectLanguages] = useState(false);

  let language = window.navigator
    ? window.navigator.language ||
      window.navigator.systemLanguage ||
      window.navigator.userLanguage
    : "en";
  language = language.substr(0, 2).toLowerCase();

  language =
    languages.findIndex((item) => item.value === language) !== -1
      ? language
      : "en";

  let currentLanguage = betStore.language ? betStore.language : language;

  let openSelectlanguages = () => {
    setOpenSelectSound(false);
    openSelectLanguages
      ? setOpenSelectLanguages(false)
      : setOpenSelectLanguages(true);
  };

  let chooseLanguage = (code) => {
    betStore.setLanguage(code);
    i18next.changeLanguage(code);
    document.documentElement.setAttribute("lang", code);
    setOpenSelectLanguages(false);
  };

  const [descriptionModalIsOpen, setDescriptionModalIsOpen] = useState(false);

  let [openSelectSound, setOpenSelectSound] = useState(false);

  let openSelectsound = () => {
    setOpenSelectLanguages(false);
    openSelectSound ? setOpenSelectSound(false) : setOpenSelectSound(true);
  };

  let onSound = () => {
    console.log("on");
    setOpenSelectSound(false);
    soundStore.clickSoundBtn();
    soundStore.setUnmute();
    document.documentElement.setAttribute("sound", "on");
  };

  let offSound = () => {
    console.log("off");
    setOpenSelectSound(false);
    soundStore.setMute();
    document.documentElement.setAttribute("sound", "off");
  };

  return (
    <div className="Settings  position-relative">
      <div
        className="Settings__item"
        onClick={() => {
          setOpenSelectSound(false);
          setOpenSelectLanguages(false);
          setDescriptionModalIsOpen(true);
        }}
      >
        <img src={info} alt="" />
      </div>
      <div className="Settings__item cursor-pointer">
        <img
          src={
            languages[
              languages.findIndex((item) => item.code === currentLanguage)
            ].img
          }
          alt=""
          onClick={() => openSelectlanguages()}
        />
        <div
          className={
            "select__languages " + (!openSelectLanguages ? "" : "open")
          }
        >
          <div className="d-flex align-items-center justify-content-between pb-1">
            <span className="select__heading">{t("language")}</span>
            <img
              className="close__btn"
              src={close}
              alt=""
              onClick={() => setOpenSelectLanguages(false)}
            />
          </div>
          {languages.map((language, key) => (
            <div
              className="item__laguages d-flex align-items-center justify-content-between p-1"
              key={key}
              onClick={() => chooseLanguage(language.code)}
            >
              <div className="d-flex align-items-center">
                <img className="mr-1" src={language.img} alt={language.code} />
                <span>{language.name}</span>
              </div>
              {currentLanguage === language.code && <img src={check} alt="" />}
            </div>
          ))}
        </div>
      </div>
      <div className="Settings__item">
        <img src={settings} alt="" onClick={() => openSelectsound()} />
        <div className={"select__sound " + (!openSelectSound ? "" : "open")}>
          <div className="d-flex align-items-center justify-content-between pb-1">
            <span className="select__heading">{t("sound")}</span>
            <img
              className="close__btn"
              src={close}
              alt=""
              onClick={() => setOpenSelectSound(false)}
            />
          </div>

          <div
            className="item__sound d-flex align-items-center justify-content-between p-1"
            onClick={() => onSound()}
          >
            <div className="d-flex align-items-center">
              <img className="mr-1" src={on} alt="" />
              <span>{t("on")}</span>
            </div>
            {!toJS(soundStore.mute) && <img src={check} alt="" />}
          </div>

          <div
            className="item__sound d-flex align-items-center justify-content-between p-1"
            onClick={() => offSound()}
          >
            <div className="d-flex align-items-center">
              <img className="mr-1" src={off} alt="" />
              <span>{t("off")}</span>
            </div>
            {toJS(soundStore.mute) && <img src={check} alt="" />}
          </div>
        </div>
      </div>
      {isMobile && isSafari && !isTablet ? (
        ""
      ) : (
        <div className="Settings__item">
          <img
            src={!opened ? fullscreen : closeFullscreenIcon}
            alt=""
            onClick={() => openFullscreen()}
          />
        </div>
      )}

      <DescriptionModal
        modalIsOpen={descriptionModalIsOpen}
        modalIsOpenCallback={(state) => setDescriptionModalIsOpen(state)}
        modalHeader={t("rules_header")}
        modalText={t("rules")}
      />
    </div>
  );
});

export default Settings;
