import { makeAutoObservable, observable, action, computed, autorun, toJS } from 'mobx'

class TableStore {
    block;
    open;

    constructor() {
        this.block = false;
        this.open = true;
    	makeAutoObservable(this);

    }

    blockTable() {
        this.block = true;
    }

    unblockTable() {
        this.block = false;
    }

    closeTable() {
        this.open = false;
        this.block = true;
    }

    openTable() {
        if (!this.open) this.open = true;
        if (!this.open) this.block = false;        
    }

}

const tableStore = new TableStore();

export default tableStore;
export { TableStore };