import adapter from 'webrtc-adapter';

export class VideoRTC {
    constructor(name, url, video, onStatusChange) {
        this.DISCONNECT_TIMEOUT = 1000;
        this.RECONNECT_TIMEOUT = 30000;

        /**
         * [config] Debug mode
         * @type {boolean||"trace"}
         */
        this.debug = true;

        /**
         * [config] WebRTC configuration
         * @type {RTCConfiguration}
         */
        this.pcConfig = {
            iceServers: [{
                urls: 'stun:88.198.161.251:80'
            },
            {
                urls: 'turn:88.198.161.251:80',
                username: 'turnuser',
                credential: 'turn456'
            }],
            iceTransportPolicy: 'relay',
            sdpSemantics: 'unified-plan',
        };

        /**
         * [info] WebSocket connection state. Values: CONNECTING, OPEN, CLOSED
         * @type {number}
         */
        this.wsState = WebSocket.CLOSED;

        /**
         * [info] WebRTC connection state.
         * @type {number}
         */
        this.pcState = WebSocket.CLOSED;

        /**
         * @type {HTMLVideoElement}
         */
        this.video = video;

        /**
         * @type {string}
         */
        this.name = name;

        /**
         * onStatus change callback (playing or loading)
         * @type {function}
         */
        this.onStatusChange = onStatusChange;

        /**
         * @type {WebSocket}
         */
        this.ws = null;

        /**
         * @type {string|URL}
         */
        this.wsURL = url;

        /**
         * @type {RTCPeerConnection}
         */
        this.pc = null;

        /**
         * @type {number}
         */
        this.connectTS = 0;

        /**
         * [internal] Disconnect TimeoutID.
         * @type {number}
         */
        this.disconnectTID = 0;

        /**
         * [internal] Reconnect TimeoutID.
         * @type {number}
         */
        this.reconnectTID = 0;

        /**
         * [internal] Handler for receiving Binary from WebSocket.
         * @type {Function}
         */
        this.ondata = null;

        /**
         * [internal] Handlers list for receiving JSON from WebSocket
         * @type {Object.<string,Function>}}
         */
        this.onmessage = null;

        // INIT
        this.onInit();
    }

    /**
     * Init stream
     */
    onInit() {
        this.debugLogger("[onInit]");

        if (this.disconnectTID) {
            clearTimeout(this.disconnectTID);
            this.disconnectTID = 0;
        }
        this.isConnected = true;
        this.onconnect();
    }

    /**
     * [public] Close connection
     */
    closeConnection() {
        this.debugLogger("[closeConnection]");

        if (this.disconnectTID) return;
        if (this.wsState === WebSocket.CLOSED && this.pcState === WebSocket.CLOSED) return;

        this.disconnectTID = setTimeout(() => {
            if (this.reconnectTID) {
                clearTimeout(this.reconnectTID);
                this.reconnectTID = 0;
            }

            this.disconnectTID = 0;

            this.ondisconnect();
        }, this.DISCONNECT_TIMEOUT);
    }

    /**
     * [public] Pause stream (no disconnecting)
     */
    pauseStream() {
        this.debugLogger("[pauseStream]", this.video.srcObject);

        if(!this.isConnected || !this.video.srcObject || !this.pc) return false;
        // copy srcObject for Safari MacOS, fix bug with autoplay video
        let srcObjectCopy = this.video.srcObject;
        srcObjectCopy.getTracks().forEach(t => {
            if(t.enabled) t.enabled = false
        });
        this.video.srcObject = srcObjectCopy;
        this.debugLogger("[pauseStream][end]", this.video.srcObject.getTracks());
    }

    /**
     * [public] Resume stream
     */
    resumeStream() {
        this.debugLogger("[resumeStream]", this.video.srcObject);

        if(!this.isConnected || !this.video.srcObject || !this.pc) return false;
        // copy srcObject for Safari MacOS, fix bug with autoplay video
        let srcObjectCopy = this.video.srcObject;
        srcObjectCopy.getTracks().forEach(t => {
            if(!t.enabled) t.enabled = true
        });
        this.video.srcObject = srcObjectCopy;
        this.debugLogger("[resumeStream][end]", this.video.srcObject.getTracks());
    }

    /**
     * Debug logger. Adds timestamp to logs.
     * @param arg {Object}
     */
    debugLogger(...arg) {
        if (this.debug) {
            if (this.debug === "trace") {
                console.trace(...arg);
            } else {
                const d = new Date();
                console.log(this.name, d.toLocaleTimeString() + `.${d.getMilliseconds()}`, ...arg);
            }
        }
    };

    /**
     * Play video
     */
    play() {
        this.debugLogger("[play]");
        this.video.setAttribute('autoplay', '');
        this.video.setAttribute('muted', '');
        this.video.setAttribute('playsinline', '');


        //this.video.defaultMuted = true;
        //this.video.muted = true;
        this.video.play();
        this.onStatusChange(this.name, "playing")
    }

    /**
     * Send message to server via WebSocket
     * @param {Object} value
     */
    send(value) {
        if (this.ws) this.ws.send(JSON.stringify(value));
    }

    /**
     * this.ws on open event handler
     * @param ev {Event}
     */
    onWsOpenEventHandler = (ev) => {
        this.onopen(ev);
    }

    /**
     * this.ws on close event handler
     * @param ev {Event}
     */
    onWsCloseEventHandler = (ev) => {
        this.onclose(ev);
    }

    /**
     * Connect to WebSocket.
     * @return {boolean} true if the connection has started.
     */
    onconnect() {
        this.debugLogger("[onconnect]");

        if (!this.isConnected || !this.wsURL || this.ws || this.pc) return false;

        this.onStatusChange(this.name, "loading");

        // CLOSED or CONNECTING => CONNECTING
        this.wsState = WebSocket.CONNECTING;

        this.connectTS = Date.now();

        this.ws = new WebSocket(this.wsURL);
        this.ws.binaryType = "arraybuffer";
        this.ws.addEventListener("open", this.onWsOpenEventHandler);
        this.ws.addEventListener("close", this.onWsCloseEventHandler);

        return true;
    }

    /**
     * Disconnect WebSocket.
     */
    ondisconnect() {
        this.debugLogger("[ondisconnect]");

        this.wsState = WebSocket.CLOSED;
        if (this.ws) {
            this.ws.removeEventListener("open", this.onWsOpenEventHandler);
            this.ws.removeEventListener("close", this.onWsCloseEventHandler);
            this.ws.removeEventListener("message", this.onWsMessageEventHandler);
            this.ws.close();
            this.ws = null;
        }

        this.pcState = WebSocket.CLOSED;
        if (this.pc) {
            this.pc.close();
            this.pc = null;
        }
    }

    /**
     * this.ws on message event handler
     * @param ev {Event}
     */
    onWsMessageEventHandler = (ev) => {
        if (typeof ev.data === "string") {
            const msg = JSON.parse(ev.data);
            this.onmessage["webrtc"](msg);
        } else {
            this.ondata(ev.data);
        }
    }

    /**
     * @returns {boolean} true if connection is starts
     */
    onopen() {
        this.debugLogger("[onopen]");

        // CONNECTING => OPEN
        this.wsState = WebSocket.OPEN;

        this.ws.addEventListener("message", this.onWsMessageEventHandler);

        this.ondata = null;
        this.onmessage = {};

        this.onwebrtc();

        return true;
    }

    /**
     * @return {boolean} true if reconnection has started.
     */
    onclose() {
        this.debugLogger("[onclose]");

        if (this.wsState === WebSocket.CLOSED) return false;

        // CONNECTING, OPEN => CONNECTING
        this.wsState = WebSocket.CONNECTING;
        this.ws.removeEventListener("open", this.onWsOpenEventHandler);
        this.ws.removeEventListener("close", this.onWsCloseEventHandler);
        this.ws.removeEventListener("message", this.onWsMessageEventHandler);
        this.ws = null;

        // reconnect no more than once every X seconds
        const delay = Math.max(this.RECONNECT_TIMEOUT - (Date.now() - this.connectTS), 0);

        this.reconnectTID = setTimeout(() => {
            this.reconnectTID = 0;
            this.onconnect();
        }, delay);

        return true;
    }

    /**
     * Creating WebRTC connection
     */
    onwebrtc() {
        this.debugLogger("[onwebrtc]");

        const pc = new RTCPeerConnection(this.pcConfig);

        /** @type {HTMLVideoElement} */
        const video2 = document.createElement("video");
        video2.addEventListener("loadeddata", ev => this.onpcvideo(ev), {once: true});

        pc.addEventListener("icecandidate", ev => {
            const candidate = ev.candidate ? ev.candidate.toJSON().candidate : "";
            if(candidate) this.send({type: "webrtc/candidate", value: candidate});
        });

        pc.addEventListener("track", ev => {
            this.debugLogger("[pc.eventlistener track]", ev);

            // when stream already init
            if (video2.srcObject !== null) return;

            video2.srcObject = ev.streams[0];
        });

        pc.addEventListener("connectionstatechange", () => {
            this.debugLogger("[pc.eventlistener connectionstatechange]", pc.connectionState);

            if (pc.connectionState === "failed" || pc.connectionState === "disconnected") {
                pc.close(); // stop next events

                this.pcState = WebSocket.CLOSED;
                this.pc = null;
                // new
                this.wsState = WebSocket.CLOSED;
                if(this.ws) {
                    this.ws.close();
                    this.ws.removeEventListener("open", this.onWsOpenEventHandler);
                    this.ws.removeEventListener("close", this.onWsCloseEventHandler);
                    this.ws.removeEventListener("message", this.onWsMessageEventHandler);
                    this.ws = null;
                }

                this.onconnect();
            }
        });

        this.onmessage["webrtc"] = msg => {
            this.debugLogger("[onmessage webrtc]", msg);

            switch (msg.type) {
                case "webrtc/candidate":
                    //if (msg.value) {
                        pc.addIceCandidate({
                            candidate: msg.value,
                            sdpMid: "0"
                        }).catch(() => console.debug);
                    //}
                    
                    break;
                case "webrtc/answer":
                    pc.setRemoteDescription({
                        type: "answer",
                        sdp: msg.value
                    }).catch(() => console.debug);
                    break;
                case "error":
                    if (msg.value.indexOf("webrtc/offer") < 0) return;
                    pc.close();
            }
        };

        // Safari doesn't support "offerToReceiveVideo"
        pc.addTransceiver("video", {direction: "recvonly"});

        pc.createOffer({iceRestart: true, offerToReceiveAudio: false, offerToReceiveVideo: true}).then(offer => {
            //this.debugLogger("my offer", offer.sdp)
            pc.setLocalDescription(offer).then(() => {
                this.send({type: "webrtc/offer", value: offer.sdp});
            });
        });

        this.pcState = WebSocket.CONNECTING;
        this.pc = pc;
    }

    /**
     * @param ev {Event}
     */
    onpcvideo(ev) {
        this.debugLogger("[onpcvideo]");

        if (!this.pc) return;
        this.debugLogger("this.pc.connectionState", this.pc.connectionState);

        /** @type {HTMLVideoElement} */
        const video2 = ev.target;
        const state = this.pc.connectionState;

        // Firefox doesn't support pc.connectionState
        if (state === "connected" || state === "connecting" || !state) {

            /** @type {MediaStream} */
            const ms = video2.srcObject;

            this.video.srcObject = ms;
            this.play();

            this.pcState = WebSocket.OPEN;

            this.wsState = WebSocket.CLOSED;
            if(this.ws) {
                this.ws.close();
                this.ws.removeEventListener("open", this.onWsOpenEventHandler);
                this.ws.removeEventListener("close", this.onWsCloseEventHandler);
                this.ws.removeEventListener("message", this.onWsMessageEventHandler);
                this.ws = null;
            }
        }

        video2.srcObject = null;
    }

    /**
     * Method encodes a string in base-64
     * @param buffer {String}
     */
    static btoa(buffer) {
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        let binary = "";
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
}