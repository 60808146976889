let table = [
		{
			isWrapper: false,
			cssClass: "", 
			content:[
				{text: "2 - 1", cssClass: "clickArea line1", value: [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36]},
				{text: "2 - 1", cssClass: "clickArea line2", value: [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35]},
				{text: "2 - 1", cssClass: "clickArea line3", value: [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34]},
			],
		},
		{
			isWrapper: true,
			cssClass: "group1 d-flex w-100",
			content: [
				{text: "3", cssClass: "clickArea Cell red", value: [3]},
				{text: "6", cssClass: "clickArea Cell black", value: [6]},
				{text: "9", cssClass: "clickArea Cell red", value: [9]},
				{text: "12", cssClass: "clickArea Cell red", value: [12]},
			]
		},
		{
			isWrapper: true,
			cssClass: "group2 d-flex w-100",
			content: [
				{text: "2", cssClass: "clickArea Cell black", value: [2]},
				{text: "5", cssClass: "clickArea Cell red", value: [5]},
				{text: "8", cssClass: "clickArea Cell black", value: [8]},
				{text: "11", cssClass: "clickArea Cell black", value: [11]},
			]
		},
		{
			isWrapper: true,
			cssClass: "group3 d-flex w-100",
			content: [
				{text: "1", cssClass: "clickArea Cell red", value: [1]},
				{text: "4", cssClass: "clickArea Cell black", value: [4]},
				{text: "7", cssClass: "clickArea Cell red", value: [7]},
				{text: "10", cssClass: "clickArea Cell black", value: [10]},
			]
		},

		{
			isWrapper: true,
			cssClass: "group4 d-flex w-100",
			content: [
				{text: "15", cssClass: "clickArea Cell black", value: [15]},
				{text: "18", cssClass: "clickArea Cell red", value: [18]},
				{text: "21", cssClass: "clickArea Cell red", value: [21]},
				{text: "24", cssClass: "clickArea Cell black", value: [24]},
			]
		},
		{
			isWrapper: true,
			cssClass: "group5 d-flex w-100",
			content: [
				{text: "14", cssClass: "clickArea Cell red", value: [14]},
				{text: "17", cssClass: "clickArea Cell black", value: [17]},
				{text: "20", cssClass: "clickArea Cell black", value: [20]},
				{text: "23", cssClass: "clickArea Cell red", value: [23]},
			]
		},
		{
			isWrapper: true,
			cssClass: "group6 d-flex w-100",
			content: [
				{text: "13", cssClass: "clickArea Cell black", value: [13]},
				{text: "16", cssClass: "clickArea Cell red", value: [16]},
				{text: "19", cssClass: "clickArea Cell red", value: [19]},
				{text: "22", cssClass: "clickArea Cell black", value: [22]},
			]
		},

		{
			isWrapper: true,
			cssClass: "group7 d-flex w-100",
			content: [
				{text: "27", cssClass: "clickArea Cell red", value: [27]},
				{text: "30", cssClass: "clickArea Cell red", value: [30]},
				{text: "33", cssClass: "clickArea Cell black", value: [33]},
				{text: "36", cssClass: "clickArea Cell red", value: [36]},
			]
		},
		{
			isWrapper: true,
			cssClass: "group8 d-flex w-100",
			content: [
				{text: "26", cssClass: "clickArea Cell black", value: [26]},
				{text: "29", cssClass: "clickArea Cell black", value: [29]},
				{text: "32", cssClass: "clickArea Cell red", value: [32]},
				{text: "35", cssClass: "clickArea Cell black", value: [35]},
			]
		},
		{
			isWrapper: true,
			cssClass: "group9 d-flex w-100",
			content: [
				{text: "25", cssClass: "clickArea Cell red", value: [25]},
				{text: "28", cssClass: "clickArea Cell black", value: [28]},
				{text: "31", cssClass: "clickArea Cell black", value: [31]},
				{text: "34", cssClass: "clickArea Cell red", value: [34]},
			]
		},

		{
			isWrapper: false,
			cssClass: "", 
			content:[
				{text: "1st12", cssClass: "clickArea cAmini cAmini--w4 group10", value: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]},
				{text: "2nd12", cssClass: "clickArea cAmini cAmini--w4 group11", value: [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]},
				{text: "3rd12", cssClass: "clickArea cAmini cAmini--w4 group12", value: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36]},
			],
		},

		{
			isWrapper: true,
			cssClass: "group13 d-flex", 
			content:[
				{text: "1to18", cssClass: "clickArea cAmini cAmini--w2 w-50 group__info", value: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]},
				{text: "EVEN", cssClass: "clickArea cAmini cAmini--w2 w-50 group__info", value: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36]},
			],
		},

		{
			isWrapper: true,
			cssClass: "group14 d-flex", 
			content:[
				{text: <div className="info__block red"></div>, cssClass: "clickArea cAmini cAmini--w2 w-50 group__info", value: [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36]},
				{text: <div className="info__block black"></div>, cssClass: "clickArea cAmini cAmini--w2 w-50 group__info", value: [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35]},
			],
		},

		{
			isWrapper: true,
			cssClass: "group15 d-flex", 
			content:[
				{text: "ODD", cssClass: "clickArea cAmini cAmini--w2 w-50 group__info", value: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35]},
				{text: "19to36", cssClass: "clickArea cAmini cAmini--w2 w-50 group__info", value: [19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36]},
			],
		},

		{
			isWrapper: false,
			cssClass: "",
			content: [
				{text: "37", cssClass: "clickArea Cell cell37", value: [37]},
			]
		},
	]

export default table;