import {useEffect, useRef, useState} from "react";
import {VideoRTC} from './VideoRTC'
import './Player.css';

const ReactPlayer = (params) => {
    /** PARAMS
     *  name (String)
     *  url (String)
     *  display (Boolean)
     *  controls (Boolean) (default: false)
     *  loop (Boolean) (default: false)
     *  onStatus (Function) - callback with name, newStatus
    */

    const playerElement = useRef(null);

    let [videoPlayer, setVideoPlayer] = useState(null);
    let [currentStatus, setCurrentStatus] = useState(null);

    useEffect(() => {
        let onStatusChange = (name, newStatus) => {
            setCurrentStatus(newStatus);
            return params.onStatus ? params.onStatus(name, newStatus) : false;
        }

        let videoPlayerInstance = new VideoRTC(params.name, params.url, playerElement.current, onStatusChange);

        setVideoPlayer(videoPlayerInstance);

        let unloadHandler = () => {
            videoPlayerInstance.closeConnection();
        }

        window.addEventListener("beforeunload", unloadHandler)

        return () => {
            unloadHandler();
            window.removeEventListener("beforeunload", unloadHandler)
        }
    }, [])

    useEffect(() => {
        if(!videoPlayer || !playerElement.current) return;
        if(params.display) {
            videoPlayer.resumeStream();
            playerElement.current.style.display = null;
        }
        else {
            videoPlayer.pauseStream();
            playerElement.current.style.display = "none";
        }
    }, [videoPlayer, params.display, playerElement.current])

    return (
        <div className="player-wrapper" style={{display: params.display ? "grid" : "none"}}>
            <div className="player-inner">
                <video 
                ref={playerElement} 
                autoPlay={true} 
                playsInline={true}
                muted={true}
                controls={params.controls || false}
                loop={params.loop || false}
                >
                </video>
                {currentStatus === "loading" ? <span className="loader"></span> : ""}
            </div>
        </div>
    )
}

export default ReactPlayer;